import { type DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import { useState } from 'react';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  getWalkInAvailability,
  seatWaitListEntry,
  type WalkInAvailability,
} from 'restaurantAdmin/reservations/apiHelpers';
import { useWaitListContext } from '../sidePanel/waitList/state/waitListContext';
import { WalkInSeatingConflictModal } from '../sidePanel/WalkInSeatingConflictModal';
import { WalkInTurnTimeInformationModal } from '../sidePanel/WalkInTurnTimeInformationModal';
import { useReservationServiceContext } from '../state/reservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface WaitListSeatModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  refreshFloorPlan: () => void;
  exitSeatMode: () => void;
  tables: HostFloorPlanTable[];
  shouldShowTimers: boolean;
}

export const WaitListSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  refreshFloorPlan,
  exitSeatMode,
  tables,
  shouldShowTimers,
}: WaitListSeatModeFloorPlanProps) => {
  const restaurant = useRestaurant();
  const { resetViewMode } = useReservationServiceContext();
  const {
    selectedWaitListEntry,
    setSelectedWaitListEntryIndex,
    fetchWaitListEntries,
  } = useWaitListContext();

  const {
    isOpen: isSeatingConflictModalOpen,
    close: closeSeatingConflictModal,
    open: openSeatingConflictModal,
  } = useIsOpen();
  const {
    isOpen: isTurnTimeInfoModalOpen,
    close: closeTurnTimeInfoModal,
    open: openTurnTimeInfoModal,
  } = useIsOpen();
  const [walkInAvailability, setWalkInAvailability] =
    useState<WalkInAvailability>();
  const [selectedFloorPlanTable, setSelectedFloorPlanTable] =
    useState<HostFloorPlanTable | null>(null);

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const floorPlanTable = over?.data.current
        ?.floorPlanTable as HostFloorPlanTable;

      if (
        over &&
        active &&
        over.data.current?.accepts.includes(active.data.current?.type)
      ) {
        const seatWaitListEntryOnDragEnd = () => onTableClick(floorPlanTable);
        void seatWaitListEntryOnDragEnd();
      }
    },
  });

  if (!selectedWaitListEntry) return null;

  const onSeatWaitList = async (
    availability: WalkInAvailability,
    floorPlanTable: HostFloorPlanTable,
  ): Promise<void> => {
    const response = await seatWaitListEntry({
      restaurantId: restaurant.id,
      floorPlanTableId: floorPlanTable.id,
      waitListEntryId: selectedWaitListEntry.id,
      hasAvailability: availability.hasAvailability,
      turnTime: availability.turnTime,
    });

    if (!response.ok) {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }

    resetViewMode();
    setSelectedWaitListEntryIndex(-1);
    exitSeatMode();
    refreshFloorPlan();
    void fetchWaitListEntries();
  };

  const onTableClick = async (floorPlanTable: HostFloorPlanTable) => {
    const response = await getWalkInAvailability({
      floorPlanTableId: floorPlanTable.id,
      guestCount: selectedWaitListEntry.guestCount,
      restaurantId: restaurant.id,
    });

    if (isSuccessResponse(response)) {
      setWalkInAvailability(response);
      setSelectedFloorPlanTable(floorPlanTable);

      if (!response.hasAvailability) {
        openSeatingConflictModal();
        return;
      }

      if (response.turnTime !== null) {
        openTurnTimeInfoModal();
        return;
      }

      await onSeatWaitList(response, floorPlanTable);
    } else {
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={() => {
            void onTableClick(floorPlanTable);
          }}
          key={floorPlanTable.id}
          shouldShowTimers={shouldShowTimers}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );
  const handleConfirm = () => {
    if (selectedFloorPlanTable && walkInAvailability) {
      void onSeatWaitList(walkInAvailability, selectedFloorPlanTable);
    }
  };
  return (
    <>
      <FloorPlan
        backgroundSrc={backgroundSrc}
        className={className}
        floorPlanTablesRenderer={floorPlanTablesRenderer}
      />
      {walkInAvailability && (
        <WalkInSeatingConflictModal
          closeModal={closeSeatingConflictModal}
          handleConfirm={handleConfirm}
          isOpen={isSeatingConflictModalOpen}
        />
      )}
      {walkInAvailability?.turnTime && (
        <WalkInTurnTimeInformationModal
          closeModal={closeTurnTimeInfoModal}
          handleConfirm={handleConfirm}
          isOpen={isTurnTimeInfoModalOpen}
          turnTime={walkInAvailability.turnTime}
        />
      )}
    </>
  );
};
