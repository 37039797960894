import { Api, type ApiGetOptions } from '@shared/api';
import { ApiUnauthorizedError } from '@shared/api/errors';

class AdminApi extends Api {
  get<T>(path: string, options?: ApiGetOptions): Promise<T> {
    try {
      return super.get(path, options);
    } catch (e) {
      if (e instanceof ApiUnauthorizedError) {
        // It might be better to use react-router here, but this works well
        // enough for now.
        // The guest app has page-specific handing of unauthorized errors.
        // Only do this for GET requests because redirecting on other requests
        // could be more disruptive.
        window.location.assign('/');
      }
      throw e;
    }
  }
}

export const api = new AdminApi();
