import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';
import { sentryCreateBrowserRouter } from '@utils/sentry';
import { PublishedListingsContextProvider } from './context/publishedListingsContext';
import { CreateAccountPage } from './auth/CreateAccountPage';
import { ForgotPasswordPage } from './auth/ForgotPasswordPage';
import { InviteExpiredPage } from './auth/InviteExpiredPage';
import { IsAuthenticated } from './auth/IsAuthenticated';
import { LoginPage } from './auth/LoginPage';
import { ResetPasswordPage } from './auth/ResetPasswordPage';
import { ErrorHandler } from './errors/ErrorHandler';
import { NotFoundHero } from './errors/NotFoundHero';
import { GuestBookPage } from './guestBook/GuestBookPage';
import { Layout } from './layout/Layout';
import { RestaurantEventForm } from './operations/events/RestaurantEventForm';
import { RestaurantEventsPage } from './operations/events/RestaurantEventsPage';
import { CreateListingContextProvider } from './operations/listings/create/CreateListingContext';
import { CreateListingDetailsPage } from './operations/listings/create/CreateListingDetailsPage';
import { CreateListingModalContainer } from './operations/listings/create/CreateListingModalContainer';
import { CreateListingTimeAndPricePage } from './operations/listings/create/CreateListingTimeAndPricePage';
import { EditListingContextProvider } from './operations/listings/edit/EditListingContext';
import { EditListingDetailsPage } from './operations/listings/edit/EditListingDetailsPage';
import { EditListingTimeAndPricePage } from './operations/listings/edit/EditListingTimeAndPricePage';
import { ListingsContextProvider } from './operations/listings/ListingsContext';
import { ListingsPage } from './operations/listings/listingsPage/ListingsPage';
import {
  CREATE_ACCOUNT_PATH,
  FORGOT_PASSWORD_ROOT_PATH,
  GUEST_BOOK_ROOT_PATH,
  INVITE_EXPIRED_PATH,
  OPERATIONS_EVENTS_CREATE_TERMINAL_PATH,
  OPERATIONS_EVENTS_TERMINAL_PATH,
  OPERATIONS_EVENTS_UPDATE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CREATE_DETAILS_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CREATE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CREATE_TIME_AND_PRICE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_DETAILS_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_TIME_AND_PRICE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_TERMINAL_PATH,
  OPERATIONS_LISTINGS_TERMINAL_PATH,
  OPERATIONS_ROOT_PATH,
  RESERVATIONS_CONCIERGE_TERMINAL_PATH,
  RESERVATIONS_OCCUPANTS_TERMINAL_PATH,
  RESERVATIONS_REPORT_PATH,
  RESERVATIONS_ROOT_PATH,
  RESERVATIONS_SERVICE_TERMINAL_PATH,
  RESERVATIONS_STRANDED_TERMINAL_PATH,
  RESET_PASSWORD_ROOT_PATH,
  SETTINGS_FINANCES_TERMINAL_PATH,
  SETTINGS_GENERAL_TERMINAL_PATH,
  SETTINGS_INVITE_MEMBER_TERMINAL_PATH,
  SETTINGS_ROOT_PATH,
  SETTINGS_TEAM_TERMINAL_PATH,
  SUPPORT_FLOOR_PLAN_EDITOR_TERMINAL_PATH,
  SUPPORT_GUEST_IMPORT_TERMINAL_PATH,
  SUPPORT_ROOT_PATH,
} from './paths';
import { ConciergePage } from './reservations/concierge/ConciergePage';
import { MergeUnmergeTablesProvider } from './reservations/mergeUnmergeTables/state/mergeUnmergeTablesContext';
import { OccupantsPage } from './reservations/occupants/OccupantsPage';
import { OccupantContextProvider } from './reservations/occupants/state/occupantContext';
import { DailyReservationsReportPage } from './reservations/report/DailyReservationsReportPage';
import { ServicePage } from './reservations/service/ServicePage';
import { WaitListContextProvider } from './reservations/service/sidePanel/waitList/state/waitListContext';
import { ReservationServiceContextProvider } from './reservations/service/state/reservationServiceContext';
import { StrandedPage } from './reservations/stranded/StrandedPage';
import { Root } from './Root';
import { FinancesPage } from './settings/finances/FinancesPage';
import { GeneralPage } from './settings/general/GeneralPage';
import { InviteTeamMemberPage } from './settings/team/InviteTeamMemberPage';
import { TeamPage } from './settings/team/TeamPage';
import { FloorPlanEditor } from './support/FloorPlanEditor';
import { FloorPlanEditorContextProvider } from './support/floorPlanEditorContext';
import { GuestImport } from './support/GuestImport';

const servicePageWithContexts = (
  <ReservationServiceContextProvider>
    <MergeUnmergeTablesProvider>
      <WaitListContextProvider>
        <PublishedListingsContextProvider>
          <ServicePage />
        </PublishedListingsContextProvider>
      </WaitListContextProvider>
    </MergeUnmergeTablesProvider>
  </ReservationServiceContextProvider>
);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorHandler />}>
      <Route element={<LoginPage />} index />
      <Route
        element={<ForgotPasswordPage />}
        path={FORGOT_PASSWORD_ROOT_PATH}
      />
      <Route element={<ResetPasswordPage />} path={RESET_PASSWORD_ROOT_PATH} />
      <Route element={<CreateAccountPage />} path={CREATE_ACCOUNT_PATH} />
      <Route element={<InviteExpiredPage />} path={INVITE_EXPIRED_PATH} />
      <Route element={<IsAuthenticated />}>
        <Route element={<Layout />}>
          <Route errorElement={<ErrorHandler />}>
            <Route path={`${RESERVATIONS_ROOT_PATH}/*`}>
              <Route element={servicePageWithContexts} index />
              <Route
                element={servicePageWithContexts}
                path={RESERVATIONS_SERVICE_TERMINAL_PATH}
              />
              <Route
                element={<ConciergePage />}
                path={RESERVATIONS_CONCIERGE_TERMINAL_PATH}
              />
              <Route
                element={<StrandedPage />}
                path={RESERVATIONS_STRANDED_TERMINAL_PATH}
              />
              <Route
                element={
                  <OccupantContextProvider>
                    <OccupantsPage />
                  </OccupantContextProvider>
                }
                path={RESERVATIONS_OCCUPANTS_TERMINAL_PATH}
              />
            </Route>
            <Route element={<GuestBookPage />} path={GUEST_BOOK_ROOT_PATH} />
            <Route path={`${OPERATIONS_ROOT_PATH}/*`}>
              <Route
                element={
                  <ListingsContextProvider>
                    <Outlet />
                  </ListingsContextProvider>
                }
                path={OPERATIONS_LISTINGS_TERMINAL_PATH}
              >
                <Route
                  element={
                    <Navigate
                      to={OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_TERMINAL_PATH}
                    />
                  }
                  index
                />
                <Route
                  element={<ListingsPage />}
                  path={OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_TERMINAL_PATH}
                />
                <Route
                  element={<ListingsPage />}
                  path={OPERATIONS_LISTINGS_CALENDAR_DRAFT_TERMINAL_PATH}
                />
                <Route
                  element={<ListingsPage />}
                  path={OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_TERMINAL_PATH}
                />
                <Route
                  element={<ListingsPage />}
                  path={OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_TERMINAL_PATH}
                />
                <Route
                  element={
                    <CreateListingContextProvider>
                      <Outlet />
                      <CreateListingModalContainer />
                    </CreateListingContextProvider>
                  }
                  path={OPERATIONS_LISTINGS_CREATE_TERMINAL_PATH}
                >
                  <Route
                    element={<CreateListingDetailsPage />}
                    path={OPERATIONS_LISTINGS_CREATE_DETAILS_TERMINAL_PATH}
                  />
                  <Route
                    element={<CreateListingTimeAndPricePage />}
                    path={
                      OPERATIONS_LISTINGS_CREATE_TIME_AND_PRICE_TERMINAL_PATH
                    }
                  />
                </Route>
                <Route
                  element={
                    <EditListingContextProvider>
                      <Outlet />
                    </EditListingContextProvider>
                  }
                  path={OPERATIONS_LISTINGS_EDIT_TERMINAL_PATH}
                >
                  <Route path=":listingId">
                    <Route
                      element={<EditListingDetailsPage />}
                      path={OPERATIONS_LISTINGS_EDIT_DETAILS_TERMINAL_PATH}
                    />
                    <Route
                      element={<EditListingTimeAndPricePage />}
                      path={
                        OPERATIONS_LISTINGS_EDIT_TIME_AND_PRICE_TERMINAL_PATH
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route path={OPERATIONS_EVENTS_TERMINAL_PATH}>
                <Route element={<RestaurantEventsPage />} index />
                <Route
                  element={<RestaurantEventForm />}
                  path={OPERATIONS_EVENTS_CREATE_TERMINAL_PATH}
                />
                <Route
                  element={<RestaurantEventForm />}
                  path={OPERATIONS_EVENTS_UPDATE_TERMINAL_PATH}
                />
              </Route>
            </Route>
            <Route path={`${SETTINGS_ROOT_PATH}/*`}>
              <Route element={<GeneralPage />} index />
              <Route
                element={<GeneralPage />}
                path={SETTINGS_GENERAL_TERMINAL_PATH}
              />
              <Route
                element={<TeamPage />}
                path={SETTINGS_TEAM_TERMINAL_PATH}
              />
              <Route
                element={<InviteTeamMemberPage />}
                path={SETTINGS_INVITE_MEMBER_TERMINAL_PATH}
              />
              <Route
                element={<FinancesPage />}
                path={SETTINGS_FINANCES_TERMINAL_PATH}
              />
            </Route>
            <Route path={`${SUPPORT_ROOT_PATH}/*`}>
              <Route
                element={
                  <FloorPlanEditorContextProvider>
                    <FloorPlanEditor />
                  </FloorPlanEditorContextProvider>
                }
                path={SUPPORT_FLOOR_PLAN_EDITOR_TERMINAL_PATH}
              />
              <Route
                element={<GuestImport />}
                path={SUPPORT_GUEST_IMPORT_TERMINAL_PATH}
              />
            </Route>
          </Route>
          <Route element={<NotFoundHero />} path="*" />
        </Route>
        <Route
          element={<DailyReservationsReportPage />}
          path={RESERVATIONS_REPORT_PATH}
        />
      </Route>
    </Route>,
  ),
);
