import { NO_CONTENT } from '@shared/statusCodes';
import { type ErrorResponse } from '@shared/types/apiHelpers';
import { createResponseError } from './createResponseError';

export interface ApiGetOptions {
  signal?: AbortSignal;
}

export class Api {
  constructor(private readonly host: string = process.env.API_HOST!) {}

  delete<T>(path: string): Promise<T> {
    return this.fetch('DELETE', path);
  }

  get<T>(path: string, options?: ApiGetOptions): Promise<T> {
    return this.fetch('GET', path, undefined, options);
  }

  post<T>(path: string, body: unknown): Promise<T> {
    return this.fetch('POST', path, body);
  }

  put<T>(path: string, body: unknown): Promise<T> {
    return this.fetch('PUT', path, body);
  }

  private async fetch<T>(
    method: string,
    path: string,
    body?: unknown,
    options?: ApiGetOptions,
  ): Promise<T> {
    const url = `${this.host}${path}`;
    const headers = new Headers();
    let bodyForFetch;
    if (body !== undefined) {
      headers.set('Content-Type', 'application/json');
      bodyForFetch = JSON.stringify(body);
    }
    const response = await fetch(url, {
      body: bodyForFetch,
      credentials: 'include',
      headers,
      method,
      signal: options?.signal,
    });
    return handleResponse<T>(response);
  }
}

const handleResponse = async <T>(response: Response): Promise<T> => {
  // T should be void for endpoints that return 204 No Content
  if (response.status === NO_CONTENT) return undefined as T;
  const body: unknown = await response.json();
  if (response.ok) return body as T;
  if (isErrorResponse(body)) throw createResponseError(body);
  throw new TypeError('invalid API error payload');
};

const isErrorResponse = (body: unknown): body is ErrorResponse =>
  Boolean(typeof body === 'object' && body && 'error' in body);
