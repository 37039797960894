import * as Sentry from '@sentry/react';
import { UNAUTHORIZED } from '@shared/statusCodes';

/** @deprecated */
export default {
  get: async (path: string, redirect = true): Promise<Response> => {
    const response = await fetch(`${process.env.API_HOST}${path}`, {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === UNAUTHORIZED && redirect) {
      window.location.assign('/');
    }

    return response;
  },

  post: async <TBody>(path: string, body?: TBody): Promise<Response> => {
    try {
      return await fetch(`${process.env.API_HOST}${path}`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  delete: async (path: string): Promise<Response> =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  put: async <TBody>(path: string, body?: TBody): Promise<Response> =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};
