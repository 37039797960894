// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceStatusSelect__main___O1T_I{position:relative}.ServiceStatusSelect__combobox___TixPJ{background:none;border:none;outline:none}.ServiceStatusSelect__listbox___v40KS{background:var(--primary400);border:1px solid var(--hairline);max-height:20rem;overflow:auto;border-radius:1rem;width:14rem}.ServiceStatusSelect__option___CJfNL{align-items:center;display:flex;gap:1rem;padding:.5rem 1rem}.ServiceStatusSelect__option___CJfNL:active,.ServiceStatusSelect__option___CJfNL:focus,.ServiceStatusSelect__option___CJfNL:hover{background-color:var(--hairline);cursor:pointer}.ServiceStatusSelect__option___CJfNL:focus-visible{outline:none}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/serviceStatus/ServiceStatusSelect.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAA,CAGF,uCACE,eAAA,CACA,WAAA,CACA,YAAA,CAGF,sCACE,4BAAA,CACA,gCAAA,CACA,gBAAA,CACA,aAAA,CACA,kBAAA,CACA,WAAA,CAGF,qCACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CAEA,kIAGE,gCAAA,CACA,cAAA,CAGF,mDACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ServiceStatusSelect__main___O1T_I",
	"combobox": "ServiceStatusSelect__combobox___TixPJ",
	"listbox": "ServiceStatusSelect__listbox___v40KS",
	"option": "ServiceStatusSelect__option___CJfNL"
};
export default ___CSS_LOADER_EXPORT___;
